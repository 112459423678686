import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`12. THIRD PARTY LINKS AND INFORMATION COLLECTION AND USE BY OUR USERS`}</h2>
    <p>{`Some of Our Services provide links to other websites. Because We do not control the information
policies or practices of these third party sites, You should review their privacy policies to
learn about how they collect and use personal information. You may also log in to some of Our Services using sign-in services
such as OAuth Connect or an Open ID provider. These services will authenticate Your identity
and provide You with the option to share certain personal information with Us, such as Your name
and email address to pre-populate Our sign up form. For example, if You take advantage of certain
of Our social media features, such as Telegram and Discord widgets, such as the “Share This”
button or interactive mini-programs that run on Our Site, the applicable social networking sites may
collect Your IP address, which page You are visiting on Our websites, and may set a cookie to
enable it to function properly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      