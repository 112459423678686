import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`5. Illegal or fraudulent activity prohibited`}</h3>
    <p>{`All User Content must comply with applicable U.S. laws and regulations, including intellectual property rights, and must not violate the rights of others.`}</p>
    <h3>{`6. User Content Promoting Hate / Violence / Targeting / Discrimination prohibited`}</h3>
    <p>{`You may not use, or facilitate or allow others to use, the Services or the HostJane Site for any content or Service that: `}</p>
    <ul>
      <li parentName="ul">{`threatens, incites, promotes, or actively encourages unlawful violence, terrorism or other serious harm to persons or property; `}</li>
      <li parentName="ul">{`engages in, or encourages, doxxing or trolling;`}</li>
      <li parentName="ul">{`engages in, encourages, promotes or celebrates unlawful violence toward any group based on race, religion, disability, gender, sexual orientation, national origin, or any other immutable characteristic.`}</li>
    </ul>
    <h3>{`7.	Unsafe, predatory, or deceptive practices prohibited`}</h3>
    <p>{`Any content or Services that we believe violates the security, integrity, or availability of any user, network, computer or communications system, software application, or network or computing device is prohibited. `}</p>
    <h3>{`8. Remote technical support services prohibited`}</h3>
    <p>{`Use of HostJane to provide services involving remote technical support or any activity needing remote access to a Buyer's computer or other device to initiate or complete a purchase or to provide the service being paid for is prohibited.`}</p>
    <h3>{`9.	Any content, use, or words related to legal minors prohibited`}</h3>
    <p>{`In addition to the General Term that no one under the age of eighteen (18) years of age ("legal minor") can hold a HostJane account; User cannot publish any content related to legal minors whatsoever on HostJane, use HostJane servers in connection with legal minors, or use words related to or referencing legal minors in any part of Site. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      