import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`C. Investigation and Enforcement`}</h2>
    <p>{`HostJane may investigate any suspected violation of Our Policies, and remove or disable access to any User Content or resource that, in our judgment, violates Our Policies, with or without notice.`}</p>
    <p>{`You agree to cooperate with HostJane to remedy any violation.  `}</p>
    <p>{`When determining whether there has been a violation of Our Policies, we may consider your ability and willingness to comply with Our Policies, including the policies and processes you have in place to prevent or identify and remove any prohibited content or activity. `}</p>
    <h3>{`1. Hosting Users`}</h3>
    <p>{`Your Services may be terminated with or without notice upon any violation of this AUP. If applicable, violations will be reported to the appropriate law enforcement agency.`}</p>
    <h3>{`2. Marketplace Users`}</h3>
    <p>{`Your account may be terminated with or without notice upon any violation of this AUP. If applicable, violations will be reported to the appropriate law enforcement agency.`}</p>
    <h3>{`3. Resellers of Hosting Servers`}</h3>
    <p>{`If there is a violation of this AUP by one of a reseller's third-party client accounts, HostJane will suspend the Server in question and will notify the reseller so that the reseller can terminate their third-party client’s account. HostJane may, but is not obligated to, monitor the account or website for any subsequent violations. The occurrence of additional violations may result in the immediate termination of Your reseller account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      